.category_container {
    width: 100%;
    max-width: 26rem;

    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;

    position: relative;
}

.category_header {
    margin-top: 5rem;

    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.category_list {
    margin-top: 1.5rem;
    padding: 0;

    list-style: none;
}

.category_item {
    box-sizing: border-box;

    margin-bottom: 1.5rem;
    padding: 1rem;

    border-radius: 1rem;
    background-color: #4B4B4B;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

    display: flex;
    flex-direction: column;
}

.category_item:last-child {
    margin-bottom: 0;
}

.category_item_image_container {
    box-sizing: border-box;

    height: 10.5rem;

    text-align: center;
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    position: relative;
}

.category_item_image {
    height: 100%;
}

.category_item_arlink {
    position: absolute;
    top: -1.25rem;
    right: 0;
}

.category_item_title {
    margin-top: 0.625rem;

    font-size: 1rem;
    line-height: 160%;
}

.category_item_price {
    margin-top: 0.625rem;
    text-align: right;

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
}

.category_item_apple_link {
    text-decoration: none;
    color: #FFFFFF;
}

.category_item_apple_link:active {
    outline: none;
}