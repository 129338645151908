.canvas_container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;
}