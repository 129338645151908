.product_container {
    width: 100%;
    max-width: 26rem;

    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 9.5rem;
    margin-left: auto;
    margin-right: auto;

    position: relative;
}

.product_header {
    margin-top: 5rem;

    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.product_item {
    margin-top: 1.5rem;
    padding: 0;

    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
}

.product_item_image {
    width: 100vw;
    max-width: 26rem;
    height: 15.75rem;
    margin-left: -1rem;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #FFFFFF;
}



.product_item_title {
    margin-top: 1rem;

    font-weight: 700;
    font-size: 1.125rem;
}

.product_item_price {
    margin-top: 0.5rem;

    font-weight: 400;
    font-size: 1.125rem;
}

.product_item_color {
    margin-top: 1rem;
}

.product_item_additional_text {
    font-weight: 700;
}

.product_item_length {
    margin-top: 0.5rem;
}

.product_item_width {
    margin-top: 0.5rem;
}

.product_item_height {
    margin-top: 0.5rem;
}

.product_item_description {
    margin-top: 1rem;
}

.product_item_no_description {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.4);
}

.product_item_no_description:first-letter {
    text-transform: capitalize;
}

.product_item_open_source {
    margin-top: 1rem;

    line-height: 160%;
}

.product_item_manufacturer_info {
    margin-top: 1rem;

    line-height: 160%;
    color: rgba(255, 255, 255, 0.4);
}


.product_item_button_ar,
.product_item_button_to_cart,
.product_item_button_ar_apple,
.product_now_in_cart {
    width: 92vw;
    max-width: 26rem;
    height: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    text-decoration: none;
    text-align: center;

    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;

    outline: none;
    border: none;
    border-radius: 0.5rem;

    position: fixed;
    z-index: 5;
}

.product_item_button_ar {
    color: #FFFFFF;
    background-color: #DF661C;

    bottom: 3.5rem;
}

.product_item_button_to_cart {
    color: #2D2D2D;
    background-color: #E7E3E9;

    bottom: 0;
}

.product_item_button_ar_apple {
    color: #FFFFFF;
    background-color: #DF661C;

    bottom: 3.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product_now_in_cart {
    color: #D15B12;
    background-color: #E7E3E9;

    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product_button_wrapper {
    width: 100%;
    margin-left: -1rem;
    height: 14.3125rem;
    background: linear-gradient(180deg, rgba(45, 45, 45, 0) 24.59%, #2D2D2D 64.99%);
    position: fixed;
    bottom: 0;
}

.product_item_button_ar_apple > img {
    position: absolute;
}


@media screen and (min-width: 900px) {
    .product_item_image {
        width: 92vw;
        max-width: 24rem;
        height: 15.75rem;
        margin-left: 0;
        margin-right: 2rem;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: #FFFFFF;
    }

    .product_item_button_ar,
    .product_item_button_to_cart,
    .product_item_button_ar_apple,
    .product_now_in_cart {
        width: 92vw;
        max-width: 24rem;
        height: 3rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        text-decoration: none;
        text-align: center;

        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 160%;

        outline: none;
        border: none;
        border-radius: 0.5rem;

        position: fixed;
        z-index: 5;
    }
}