.catalog_container {
    max-width: 26rem;

    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    position: relative;
}

.catalog_header{
    margin-top: 5rem;

    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.catalog_list {
    margin-top: 1.5rem;
    padding: 0;

    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.catalog_item {
    flex-basis: 47.789%;
    height: 12.5rem;
    margin-bottom: 1rem;

    border-radius: 1rem;
    text-decoration: none;

    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
}

.catalog_item:nth-child(5) {
    margin-bottom: 0;
}

.catalog_item:nth-child(6) {
    margin-bottom: 0;
}

.item_link_category {
    width: 100%;
    height: 100%;

    text-decoration: none;

    display: flex;
    align-items: flex-end;
}

.item_link_category_title {
    width: 60%;
    margin-left: 1rem;
    margin-bottom: 1rem;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #FFFFFF;
}

.video_container {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(20px);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

.video_wrapper {
    width: max-content;
    height: 80%;
    max-width: 26rem;

    background-color: #2D2D2D;
    margin: 0 auto;

    position: fixed;
    top: 8%;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    box-shadow: 0 0 34px 14px rgba(0, 0, 0, 0.25);
}

.video_close {
    width: 100%;
    height: 2rem;

    background-color: #2D2D2D;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    display: flex;
    justify-content: flex-end;
}

.video_wrapper video {
    width: auto;
    height: 100%;

    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
