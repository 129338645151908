.ar_helper_container {
    width: 92vw;
    max-width: 26rem;
    height: 5.25rem;

    position: absolute;
    top: 7.5rem;
    left: 50%;
    transform: translate(-50%, 0);

    background-color: rgba(49, 49, 49, 0.8);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ar_helper {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
}

.ar_helper_plane {
    animation: 1s ease-out ar-helper-plane;
}

@keyframes ar-helper-plane {
    0% {
        opacity: 0
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ar_helper_plane_image {
    position: absolute;
    width: 280px;
    top: 12rem;
}
