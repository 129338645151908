* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    /*height: calc(100vh - 60px);*/
    height: 100%;
    margin: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body,
#root {
    height: 100%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #2D2D2D;
    color: #FFFFFF;
}

#root {
    /*height: calc(100vh - 60px);*/
    margin: 0 auto;

    display: flex;
    flex-direction: column;
}

.app_main {
    box-sizing: border-box;

    width: 100%;
    margin: 0 auto;

    flex: 1 0 auto;
    position: relative;

    display: flex;
    justify-content: center;
}

#ARButton {
    display: none;
}