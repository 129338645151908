@keyframes show {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.ar_support_container {
    width: 92vw;
    max-width: 26rem;
    height: 3rem;

    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    animation: linear 0.3s show;
}

.ar_info_close_container {
    width: 4rem;
    height: 3rem;

    background-color: rgba(49, 49, 49, 0.8);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    position: absolute;
    left: 0;
    top: 0;
    display: block;
}

.ar_info_close {
    width: 1.15rem;
    height: 1.15rem;

    position: absolute;
    left: 1.425rem;
    top: 0.925rem;
}

.ar_info_reset_container {
    box-sizing: border-box;
    width: 4rem;
    height: 3rem;

    background-color: rgba(49, 49, 49, 0.8);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-left: 1px solid rgba(255, 255, 255, 0.4);

    position: absolute;
    top: 0;
    left: 4rem;
}

.ar_info_reset {
    width: 1.15rem;
    height: 1.15rem;

    position: absolute;
    left: 1.425rem;
    top: 0.925rem;
}

.ar_info_cart_container {
    width: 4rem;
    height: 3rem;

    background-color: rgba(49, 49, 49, 0.8);
    border-radius: 0.5rem;

    position: absolute;
    top: 0;
    right: 0;
}

.ar_info_cart {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
}

.ar_info_container {
    width: 92vw;
    max-width: 26rem;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    flex-direction: column;

    animation: ease-in-out 1s show;
}

.ar_info {
    box-sizing: border-box;
    padding: 1rem;

    background-color: rgba(49, 49, 49, 0.8);
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
}

.ar_info_main {
    display: flex;
    justify-content: space-between;
}

.ar_info_main_title {
    font-weight: 700;
    margin-right: 5px;
    font-size: 1.125rem;
    line-height: 160%;
}

.ar_info_main_price {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;

    white-space: nowrap;
}

.ar_info_main_noprice{
    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;

    white-space: nowrap;
}

.ar_info_additional {
    margin-top: 1rem;
}

.ar_info_additional div {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
}

.ar_info_additional span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.ar_info_now_in_cart {
    height: 3rem;
    margin-top: 1.5rem;

    font-family: Inter, sans-serif;
    font-size: 1rem;
    line-height: 160%;
    font-weight: 400;

    color: #FF9038;

    display: flex;
    align-items: center;
    justify-content: center;
}

.ar_info_not_in_store{
    height: 3rem;
    margin-top: 1.5rem;

    font-family: Inter, sans-serif;
    font-size: 1rem;
    line-height: 160%;
    font-weight: 400;

    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
}

.ar_info_button_buy {
    height: 3rem;
    margin-top: 1.5rem;

    background-color: #DF661C;
    color: #FFFFFF;

    outline: none;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);

    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;
}

.ar_button_one_click_buy_container {
    width: 92vw;
    max-width: 26rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
}

.ar_button_one_click_buy {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    background-color: #E7E3E9;
    color: #524B55;

    outline: none;
    border: none;
    border-radius: 0.5rem;

    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;
}

.ar_button_one_click_buy_not_visible{
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    background-color: transparent;
    color: transparent;

    outline: none;
    border: none;
    border-radius: 0.5rem;

    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;
}
